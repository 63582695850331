import React from "react";
import { Layout } from "../components/Layout";

export default function () {
  return (
    <Layout title="Datenschutz" description="Datenschutz bei den Heidekojoten">
      <article className="prose md:prose-xl mx-3 md:mx-auto my-24">
        <h1>Datenschutzerklärung</h1>
        <p>
          Datenschutz ist uns ein besonders wichtiges Anliegen, und
          selbstverständlich halten wir uns an die geltenden
          datenschutzrechtlichen Vorgaben. Wir möchten Sie daher nachfolgend
          über die mit dem Besuch dieser Website verbundene Verarbeitung von
          personenbezogenen Daten aufklären und Sie über Ihre entsprechenden
          Rechte informieren: Verantwortlicher für die Datenverarbeitung:
          Verantwortlicher im Sinne des Art. 4 Nr. 7 DSGVO, an den Sie sich bei
          Fragen oder einem anderen Anliegen zum Datenschutz wenden können, ist:
          Heidekojoten ‘09 e.V. Soltau (vertreten durch: Vorstand gem. § 26 BGB,
          1. Vorsitzender Denis Krüger) Töpingen 33, 29633 Munster,
          0174-5697977, denis.krueger@heidekojoten.de.
        </p>
        <p>
          Welche Daten werden erfasst und verarbeitet? Beim Besuch unserer
          Website, werden auf verschiedene Weise personenbezogene Daten der
          Nutzer erfasst. Personenbezogene Daten sind solche Daten, mit denen
          eine natürliche Person identifiziert werden kann. Dazu gehören
          beispielsweise der Name des Betroffenen, seine Wohnadresse, seine
          Telefonnummer oder auch sein Geburtsdatum. Wenn Sie unsere Seite
          aufrufen, werden zum einen durch Ihren Browser automatisch
          Informationen an den Server unserer Website gesendet, sog.
          Zugriffsdaten bzw. ServerLogfiles, wie z.B. der verwendete Webbrowser
          und das verwendete Betriebssystem, der Domain-Name Ihre
          Internet-Providers, Ihre IP-Adresse oder auch der sog. Zeitstempel,
          also 11 der genaue Zeitpunkt und die Dauer Ihres Besuchs. Diese Daten
          müssen notwendigerweise erfasst werden um technisch eine Darstellung
          und Verbindung herzustellen. Eine Datenauswertung erfolgt jedoch
          nicht, auch nicht in anonymisierter Form. Darüber hinaus erfassen wir
          diejenigen personenbezogenen Daten, die Sie selbst bewusst auf unserer
          Homepage angeben, etwa durch Nutzung unseres Kontaktformulars oder
          unseres Antragsformulars für eine Mitgliedschaft. Die Verwendung
          unseres Kontaktformulars ist nicht mit einer Speicherung
          personenbezogenen Daten auf unserem Server verbunden; das Formular
          wird als E-Mail versandt.)
        </p>
        <p>Zusammenfassend handelt es sich um folgende Kategorien von Daten:</p>
        <ul>
          <li>
            Kontaktdaten (Name, Adresse, E-Mail-Adresse, Telefonnummer, etc.)
          </li>
          <li>
            Zahlungs- bzw. Bankverbindungsdaten (nur soweit im Zusammenhang mit
            einem Online-Mitgliedsantrags oder bei Online-Spenden abgefragt)
          </li>
          <li>
            ggf. weitere Daten, die im Rahmen einer Online-Antragstellung
            abgefragt werden
          </li>
          <li>
            inhaltliche Daten (eingestellte Texte und übersandte Nachrichten,
            eingestellte Fotos, ggf. Videos) oder Kommunikationsdaten
            (Geräte-Informationen, IP-Adresse)
          </li>
          <li>Nutzungsdaten (besuchte Website, Nutzungsdauer etc.)</li>
        </ul>
        <h2>Rechtsgrundlage für die Verarbeitung</h2>
        <p>
          Als maßgebliche Rechtsgrundlagen für die Zulässigkeit der Verarbeitung
          dienen die EUDatenschutzgrundverordnung vom 27.04.2016 sowie das
          Bundesdatenschutzgesetz i.d.F. v. 30.06.2017. Die Rechtmäßigkeit der
          Datenerfassung ergibt sich aus Art. 6 DSGVO. Soweit die
          Datenverarbeitung aufgrund einer ausdrücklichen Einwilligung erfolgt,
          dient Abs. 1 a) der genannten Norm als Rechtsgrundlage (ggf. auch Art.
          9 Abs. 2 a) DSGVO bei Gesundheitsdaten). Für die Speicherung und
          sonstige Verarbeitung Ihrer Daten im Zusammenhang mit der Verwendung
          unseres Online-Mitgliedsantrages ist das mitgliedschaftliche
          Vertragsverhältnis zwischen Ihnen und dem Verein in Verbindung mit
          Art. 6 Abs. 1 b) DSGVO Rechtsgrundlage. Das gilt auch für alle anderen
          Datenverarbeitungen, die zur Durchführung vertraglicher Maßnahmen,
          aber auch zur Beantwortung von Anfragen erforderlich sind. Soweit wir
          im Einzelfall zur Datenverarbeitung rechtlich verpflichtet sind, ist
          hierfür Art. 6 Abs. 1 c) DSGVO Grundlage; im dem Fall, dass die
          Verarbeitung der Wahrung unserer berechtigten Interessen dient, Art. 6
          Abs. 1 f) DSGVO.12 Zweck und Dauer der Datenverarbeitung: Die
          automatische Erfassung Ihrer Nutzer- und Kommunikationsdaten beim
          Besuch unserer Website dient notwendigerweise der Herstellung der
          technischen Verbindung und Nutzbarkeit unserer Website. Soweit Sie das
          auf unserer Homepage enthaltene Kontaktformular verwenden oder Sie uns
          über die Seite eine E-Mail schicken, dient die Erfassung der in diesem
          Zusammenhang bekannt gegebenen Kontaktdaten (in der Regel nur Name und
          E-Mail-Adresse) lediglich der entsprechenden Rückmeldung unsererseits.
          Eventuelle zusätzliche Angaben, die Sie uns übermitteln, werden allein
          dem Zweck und Anlass entsprechend verarbeitet. Nach Wegfall des
          Grundes für die Speicherung, werden die Daten unverzüglich gelöscht
          oder – sofern im Einzelfall erforderlich – gesperrt. Wenn Sie unser
          Online-Formular für einen Antrag auf Vereinsmitgliedschaft nutzen,
          werden Ihre dort gemachten Angaben allein zur Begründung und
          Durchführung des mitgliedschaftlichen Vertragsverhältnisses verwendet.
          Diese Daten werden lediglich für die Dauer der Vereinsmitgliedschaft
          im Rahmen unserer Mitgliederverwaltung gespeichert und ausschließlich
          hierfür genutzt. Nach Beendigung der Mitgliedschaft werden die Daten
          gelöscht oder – soweit notwendig – gesperrt.
        </p>
        <h2>Weitergabe von personenbezogenen Daten</h2>
        <p>
          Die von Ihnen übermittelten personenbezogenen Daten werden nicht an
          Dritte weitergegeben, weder zu kommerziellen noch zu
          nichtkommerziellen Zwecken
        </p>
        <h2>Datensicherheit</h2>
        <p>
          Wir versichern, dass wir die nach Art. 32 DSGVO erforderlichen
          technischen sowie organisatorischen Maßnahmen ergriffen haben, um ein
          dem Risiko für die Rechte und Freiheiten des Nutzers angemessenes
          Schutzniveau zu gewährleisten. Dies betrifft insbesondere die Auswahl
          der verwendeten Hard- und Software sowie die innerorganisatorische
          Kontrolle und Zugriffsberechtigung. Nichtsdestotrotz wird vorsorglich
          darauf hingewiesen, dass die Datenübertragung im Internet (z.B. per
          E-Mail) immer gewisse Sicherheitslücken aufweisen und nicht
          vollumfänglich vor dem Zugriff durch Dritte geschützt werden kann.
        </p>
        <h2>Rechte des Betroffenen</h2>
        <p>
          Sie haben hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten
          nach den geltenden datenschutzrechtlichen Bestimmungen insbesondere
          einen Anspruch auf
        </p>
        <ul>
          <li>
            Information und Auskunft über die von uns vorgenommene Verarbeitung
            Ihrer personenbezogenen Daten (Art. 15 DSGVO),
          </li>
          <li>
            auf Berichtigung und Vervollständigung bezüglich unrichtiger bzw.
            unvollständiger Daten (Art. 16 DSGVO) und
          </li>
          <li>
            auf Löschung nach Maßgabe des Art. 17 DSGVO bzw. auf Einschränkung
            nach Art. 18 DSGVO.
          </li>
        </ul>
        <p>
          Widerspruch und Widerruf von Einwilligungen: Soweit Ihre
          personenbezogenen Daten im Rahmen des Art. 6 Abs. 1 f) DSGVO erhoben
          und verarbeitet werden (d.h. bei Vorliegen berechtigter Interessen des
          Verantwortlichen), haben Sie nach Art. 21 DSGVO das Recht, hiergegen
          jederzeit Widerspruch einzulegen, wenn insoweit Gründe bestehen, die
          sich aus Ihrer besonderen Situation ergeben, oder wenn sich der
          Widerspruch gegen eine Direktwerbung richtet. Soweit eine
          Datenverarbeitung aufgrund Ihrer diesbezüglichen vorherigen
          Einwilligung erfolgt ist, steht es Ihnen jederzeit frei, Ihre
          Einwilligung in den betreffenden Datenverarbeitungsvorgang zu
          widerrufen. Sowohl für den Widerspruch als auch für den Widerruf
          genügt eine einfache Mitteilung per E-Mail an uns. Die betreffenden
          personenbezogenen Daten werden dann unverzüglich gelöscht.
        </p>
        <h2>Beschwerderecht</h2>
        <p>
          Nach Art. 77 DSGVO hat ein Betroffener im Falle eines
          datenschutzrechtlichen Verstoßes das Recht, sich mit einer
          entsprechenden Beschwerde an die zuständige Aufsichtsbehörde zu
          wenden. Hierbei handelt es sich um den Datenschutzbeauftragten des
          Landes Niedersachsen.
        </p>
        <h2>Verschlüsselung</h2>
        <p>
          Aus Sicherheitsgründen nutzen wir auf unserer Seite eine SSL- bzw.
          TLS-Verschlüsselung, was Sie an dem Schloss-Symbol in der Browserzeile
          sowie an der Abkürzung „https://“ (statt „http://“) erkennen können.
          Ist die Verschlüsselung aktiv, können Daten bzw. inhaltliche Angaben,
          die Sie an uns übermitteln, von Dritten nicht mitgelesen werden.
          (Hinweis i.d.R. nicht zwingend erforderlich; vor allem nur dann
          statthaft, wenn tatsächlich Verschlüsselung stattfindet, z.B. im
          Zusammenhang mit Online-Spenden)
        </p>
        <h2>Cookies</h2>
        <p>
          Um die Nutzung bestimmter Funktionen auf unserer Webseite zu
          ermöglichen, verwenden wir sog. Cookies. Hierbei handelt es sich um
          kleine Textdateien, die auf Ihrem Rechner abgelegt werden, um Sie bei
          Ihrem nächsten Besuch auf unserer Internetseite wiederzuerkennen. Sie
          ersparen Ihnen dann z.B. die wiederholte Eingabe von Daten und
          erleichtern zugleich die Übermittlung bestimmter Inhalte der Webseite.
          Cookies richten auf Ihrem Computer keinen Schaden an und enthalten
          auch keine Computerviren. Selbstverständlich können Sie den Einsatz
          dieser Cookies verweigern; insoweit bitten wir Sie, die Cookies bei
          den Sicherheitseinstellungen Ihres Browsers zu deaktivieren (ggf.
          nähere Anleitung, welche Schritte erforderlich sind). Auch ist es
          möglich, Cookies – soweit sie nach Beendigung Ihres Besuches auf
          unserer Homepage ohnehin nicht automatisch gelöscht werden – im
          Nachgang zu löschen. Wir weisen allerdings darauf hin, dass die
          Nichtaktivierung von Cookies zu Einschränkungen bei den betreffenden
          Funktionen führen kann.
        </p>
      </article>
    </Layout>
  );
}
